/********************************
	Developed by AXA Studios
	The Creative Engine
	http://www.axastudios.com
*********************************/

:root {
	--main-color: #B22278;
	--sub-color: #7A0049;
	--hover-color: #B22278;
	--dark-color: #161C24;
	--light-color: #E9EFF7;
	--grey-color: #85898d;
	--white-color: #FFFFFF;
	--text-light: rgba(255, 255, 255, 0.72);
	--light-white: rgba(255, 255, 255, 0.64);
	--light-bg: rgba(255, 255, 255, 0.08);
	--border-color: #E2E2E2;
	--border-dark-color: #263A58;
	--xs-pad: 8px;
	--sm-pad: 16px;
	--main-pad: 24px;
	--md-pad: 32px;
	--lg-pad: 48px;
	--xl-pad: 72px;
	--xxl-pad: 96px;
	--lg-neg: -48px;
	--main-neg: -24px;
	--main-font: 16px;
	--sm-font: 14px;
	--input-font: 16px;
	--button-font: 14px;
	--sm-icon: 18px;
	--main-icon: 32px;
	--large-icon: 96px;
}

/* MAIN STYLES *****************************/

html {
	overflow-y: scroll;
	margin: 0;
	padding: 0;
	font-size: 16px;
}

body {
	padding: 0px;
	margin: 0px;
	background: var(--dark-color);
	font-family: 'soleil', Arial, Helvetica, sans-serif;
	font-weight: 300;
	font-size: 16px;
	line-height: 1.6;
	color: #FFFFFF;
	color: var(--white-color);
}

/* TEXT & LINK STYLES *****************************/

h1, h2, h3, h4, h5, h6 {
	font-family: 'soleil', Arial, Helvetica, sans-serif;
	margin-top: 0px;
}

h1 {
	font-size: 48px;
	font-weight: 800;
	line-height: 1.2;
	margin-bottom: var(--main-pad);
	/*    color: var(--sub-color);*/
}

h2 {
	font-size: 32px;
	font-weight: 800;
	/*	text-transform: uppercase;*/
	line-height: 1.4;
	margin-bottom: var(--sm-pad);
}

h3 {
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 8px;
	line-height: 1;
}

h4 {
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 8px;
	line-height: 1;
}

h2 span, h3 span {
	color: var(--sub-color);
}

.sub-title {
	display: block;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 12px;
	margin-bottom: var(--sm-pad);
	color: var(--hover-color);
}

.italic {
	font-style: italic;
}

p {
	margin-bottom: 2.4rem;
	margin-bottom: 24px;
	/*	color: var(--grey-color);*/
}

.lead, .page-intro p, .article-intro p {
	font-weight: 300;
	font-size: 26px;
	margin-bottom: 2.4rem;
	line-height: 1.6;
}

h3+.lead {
	margin-top: 1.6rem;
}

/*
.large-txt{
    font-size: 22px;
}
*/

:focus {
	-moz-outline-style: none;
}

::selection {
	background-color: #333333;
	color: #fff;
}

::-moz-selection {
	background-color: #333333;
	color: #fff;
}

img {
	max-width: 100%;
}

input:focus, button:focus {
	outline: none;
}

a, a:visited {
	color: var(--white-color);
	border: none;
	outline: 0;
	-moz-outline-style: none;
	-webkit-transition: color 0.2s ease;
	transition: color 0.2s ease;
}

a:hover, a:focus, a:active {
	text-decoration: none;
	color: inherit;
	color: var(--main-color);
	-webkit-transition: color 0.4s ease-out;
	transition: color 0.4s ease-out;
}

a img {
	border: none;
	outline: 0;
	-moz-outline-style: none;
}

.page p a {
	font-weight: 700;
	text-decoration: underline;
}

input.button, .button, .button:visited, .w-btn, .l-btn, .c-btn {
	position: relative;
	cursor: pointer;
	color: var(--white-color);
	line-height: 24px;
	font-size: var(--button-font);
	font-weight: 600;
	border: none;
	border-radius: 5px;
	outline: none;
	background: var(--main-color);
	background: linear-gradient(rgb(176, 32, 118) 0%, rgb(129, 2, 80) 100%);
	text-align: center;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	text-decoration: none;
}

a.button, .button {
	color: var(--white-color);
	display: inline-block;
	text-decoration: none!important;
	padding: 8px 24px 8px 24px;
}

.btn.focus, .btn:focus {
	outline: 0;
	box-shadow: none;
}

.button:hover, .button:active, .button:focus {
	background-color: var(--hover-color);
	/*	background: linear-gradient(rgb(129, 2, 80) 0%, rgb(129, 2, 80) 100%);*/
	box-shadow: none;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.button span {
	position: relative;
	display: block;
	z-index: 2;
}

.button:before {
	position: absolute;
	content: '';
	display: block;
	z-index: 1;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	border-radius: 5px;
	background: var(--sub-color);
	opacity: 0;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.button:hover:before, .button:active:before, .button:focus:before {
	opacity: 1;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.l-btn, .l-btn:visited {
	display: inline-block;
	text-decoration: none;
	padding: 7px 24px 7px 24px;
	color: var(--white-color);
	background: rgba(255, 255, 255, 0.16);
	border: 1px solid rgba(255, 255, 255, 0.48);
	box-shadow: none;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.l-btn:hover, .l-btn:active, .l-btn:focus {
	background: none;
	color: var(--hover-color);
	border: 1px solid var(--hover-color);
	box-shadow: none;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.btn-clean, .btn-clean:visited, a.btn-clean, a.btn-clean:visited {
	display: inline-block;
	font-size: var(--button-font);
	color: var(--white-color);
	border: none;
	background: none;
	box-shadow: none;
	line-height: 24px;
	padding: 8px 24px;
	cursor: pointer;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	text-decoration: none;
}

.btn-clean i {
	display: inline-block;
	height: 24px;
	line-height: 24px;
	width: 24px;
	text-align: center;
	vertical-align: top;
	margin-right: 4px;
}

.btn-clean:hover, .btn-clean:active, .btn-clean:focus, a.btn-clean:hover, a.btn-clean:active, a.btn-clean:focus {
	color: var(--hover-color);
	border: none;
	background: none;
}

.i-btn, .i-btn:visited {
	display: block;
	text-decoration: none;
	padding: 0px;
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 18px;
	color: var(--white-color);
	background: none;
	border: none;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.i-btn:hover, .i-btn:active, .i-btn:focus {
	background: none;
	color: var(--hover-color);
	border: none;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.l-btn:before, .btn-clean:before, .i-btn:before {
	display: none;
}

.btn-group .btn {
	position: relative;
	display: flex;
	/*    line-height: 24px;*/
	padding: 11px 16px;
	padding: 0px;
	font-size: 14px;
	border: 1px solid var(--border-color);
	color: var(--grey-color);
	background: #FFFFFF;
}

.btn-group .btn span {
	width: 48px;
	line-height: 46px;
	font-size: 20px;
	text-align: center;
	vertical-align: middle;
}

.btn-group .btn span i {}

.btn-group .btn:hover, .btn-group .btn.active {
	color: var(--sub-color);
}

.btn-group .btn div {
	width: 48px;
	text-align: left;
	line-height: 46px;
}

.txt-link, .txt-link:visited, .btn-link, .btn-link:visited {
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	box-shadow: none;
	text-decoration: none;
	color: var(--nav-light-color);
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.btn-link {
	font-weight: 600;
	text-decoration: none;
}

.txt-link:hover, .txt-link:focus, .txt-link:active, .btn-link:hover, .btn-link:focus, .btn-link:active {
	color: var(--hover-color);
	text-decoration: none;
}

.light-white {
	color: var(--text-light);
}

@-moz-document url-prefix() {}

.big-btn {
	padding: 24px 48px;
	font-size: 20px;
}

.button:disabled {
	opacity: 0.48;
	cursor: default;
}

.button:disabled:hover:before {
	display: none!important;
}

/* FORM STYLES *****************************/

.form-control {
	display: block;
	width: 100%;
	height: 48px;
	padding: 8px 16px;
	font-size: var(--main-font);
	font-weight: 300;
	line-height: 24px;
	color: var(--white-color);
	border: 1px solid rgba(255, 255, 255, 0.48);
	background: rgba(255, 255, 255, 0.16);
	border-radius: 5px;
	box-shadow: none;
	/*    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;*/
}

.form-control:focus {
	color: var(--white-color);
	border: 1px solid var(--main-color);
	background: rgba(255, 255, 255, 0.16);
	outline: 0;
	/*    box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);*/
	box-shadow: none;
}

.form-control::-webkit-input-placeholder {
	color: var(--white-color);
}

.form-control::-moz-placeholder {
	color: var(--white-color);
}

.form-control:-ms-input-placeholder {
	color: var(--white-color);
}

.form-control::placeholder {
	color: var(--white-color);
}

/* WRAPPER STYLES *****************************/

#outer-wrapper {
	position: relative;
	min-height: 100vh;
}

#bg-wrapper {
	position: fixed;
	content: '';
	display: none;
	opacity: 0;
	width: 100%;
	height: 150%;
	z-index: 10;
	background: var(--dark-color);
}

.menu-open #bg-wrapper {
	display: block;
	opacity: 0.9;
	/*
    -webkit-animation: opac-in-animate 0.5s ease-out forwards;
	animation: opac-in-animate 0.5s ease-out forwards;
*/
	-webkit-transition: opacity 0.5s ease-out;
	transition: opacity 0.5s ease-out;
}

.menu-ani #bg-wrapper {
	display: block;
	opacity: 0.9;
	-webkit-animation: opac-out-animate 0.3s ease-in forwards;
	animation: opac-out-animate 0.3s ease-in forwards;
}

#main-wrapper {
	position: relative;
}

/* HEADER *****************************/

#navbar {
	position: absolute;
	display: block;
	width: 100%;
	padding: 4px 24px;
	top: 0px;
	z-index: 99;
	background: none;
}

#navbar.sticky {
	position: fixed;
	/*    box-shadow: 0px 1px 0px #E2E2E2;*/
	background: var(--dark-color);
}

#navbar .container {
	/*    max-width: 100%;*/
	padding: 0;
}

.sub-page #navbar .container {
	/*    max-width: 100%;*/
	padding: 0;
}

.navbar-header {
	position: relative;
}

.logo-main {
	display: inline-block;
	float: left;
	align-self: flex-start;
	margin: 16px 24px 16px 16px;
	padding: 0px;
}

.logo-main.carnage {
	margin-left: 0;
	margin-right: 0;
}

.logo-main img {
	height: 44px;
	height: 32px;
	width: auto;
}

.logo-main span {
	position: relative;
	display: block;
}

.logo-main .logo-text {
	padding: 8px 0px;
	color: #FFFFFF;
	font-size: 18px;
	font-weight: 300;
	line-height: 20px;
}

#navbar .logo-main-dark {
	display: none;
}

#navbar.sticky .logo-main-dark {
	display: block;
}

.logo-mob, .nav-back-btn, .nav-share-btn {
	display: none;
}

.nav-toggle {
	position: relative;
	float: left;
	cursor: pointer;
	display: none;
	width: 24px;
	height: 24px;
	padding: 0px;
	margin: 16px 16px 16px 0px;
	background: none;
	border: none;
	overflow: hidden;
}

.nav-icon span, .menu-ani .nav-icon span {
	display: block;
	position: absolute;
	top: 11px;
	left: 3px;
	right: 3px;
	height: 2px;
	background: var(--white-color);
	transition: background 0s 0.2s;
}

.nav-icon span::before, .nav-icon span::after, .menu-ani .nav-icon span::before, .menu-ani .nav-icon span::after {
	position: absolute;
	display: block;
	left: 0;
	width: 100%;
	height: 2px;
	background-color: var(--white-color);
	content: "";
	transition-duration: 0.2s, 0.2s;
	transition-delay: 0.2s, 0s;
}

.nav-icon span::before, .menu-ani .nav-icon span::before {
	top: -5px;
	transition-property: top, transform;
}

.nav-icon span::after, .menu-ani .nav-icon span::after {
	/*    width: 70%;*/
	bottom: -5px;
	transition-property: bottom, transform;
}

.menu-open .nav-icon span {
	background: none;
}

.menu-open .nav-icon span::before {
	top: 0;
	width: 100%;
	transform: rotate(45deg);
}

.menu-open .nav-icon span::after {
	bottom: 0;
	width: 100%;
	transform: rotate(-45deg);
}

.menu-open .nav-icon span::before, .menu-open .nav-icon span::after {
	transition-delay: 0s, 0.2s;
}

/* NAV MAIN *****************************/

.navbar-header {
	display: flex;
	flex-direction: row;
}

.nav-wrap {
	display: flex;
	width: 100%;
}

#nav-main {
	position: relative;
	display: inline-block;
}

#nav-right {
	position: relative;
	display: flex;
	margin-left: auto;
}

#nav-main ul {
	position: relative;
	margin: 8px 0px;
	padding: 0px;
	list-style: none;
	text-align: left;
}

#nav-main ul li {
	position: relative;
	display: inline-block;
	float: left;
	padding: 0px 24px;
	padding: 8px 0px;
	margin: 0px 24px;
	text-align: center;
}

#nav-main ul li a {
	/*    position: relative;*/
	display: block;
	line-height: 20px;
	padding: 10px 0px;
	color: var(--white-color);
	font-weight: 400;
	font-size: 14px;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	cursor: pointer;
}

#nav-main ul li a {
	letter-spacing: 1px;
}

/*
#navbar.sticky #nav-main ul li a,{
    color: var(--main-color);
}
*/

#nav-main ul li a:hover, #navbar.sticky #nav-main ul li a:hover {
	color: var(--main-color);
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

#nav-main ul li.n-btn {
	padding: 0px;
	margin-left: 30px;
}

#nav-main .show-mob {
	display: none!important;
}

#navbar.sticky, .menu-open #navbar {
	position: fixed;
}

#nav-right .button {
	margin: 16px 8px;
	display: flex;
	align-items: center;
}

#nav-right .button i {
	margin-right: 8px;
}

/*
#navbar .btn-clean, #navbar .btn-clean:visited, #navbar a.btn-clean, #navbar a.btn-clean:visited{
	color: var(--white-color);
}
*/

/*
#navbar.sticky .l-btn, #navbar.sticky .l-btn:visited{
	color: var(--main-color);
	border: 1px solid var(--main-color);
}
*/

#navbar.sticky .btn-clean, #navbar.sticky .btn-clean:visited, #navbar.sticky a.btn-clean, #navbar.sticky a.btn-clean:visited, #navbar.sticky .dropdown-toggle::after {
	color: var(--white-color);
}

.navbar-header:after, #nav-main ul:after, .icon-blocks:after {
	content: '';
	display: block;
	clear: both;
}

/* CONTENT STYLES *****************************/

#content-top-wrapper {
	position: relative;
	padding-top: 96px;
}

#content-wrapper {
	position: relative;
	padding-bottom: 96px;
}

#content-top-block {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 70vh;
	justify-content: center;
	text-align: center;
	padding: 0 17%;
}

#content-top-block h1 {
	margin: 0;
	z-index: 9;
	text-shadow: 0px 0px 10px #000000B3;
}

.content-banner-wrap {
	position: relative;
	display: block;
	padding: 120px 0px;
	z-index: 1;
}

.content-banner {
	position: relative;
	display: flex;
	flex-direction: row;
	margin-left: -48px;
	margin-right: -48px;
}

.banner-blk-txt, .banner-blk-img {
	position: relative;
	display: flex;
	width: 50%;
	padding: 0px 48px;
	align-content: flex-start;
	align-self: center;
	flex-direction: column;
}

.content-banner-title {
	position: relative;
	display: block;
	margin-bottom: 32px;
}

.content-banner-title h2 {
	line-height: 1;
	margin: 0;
}

.content-title-center {
	text-align: center;
	margin-bottom: 48px;
}

.content-title-center h2 {
	text-align: center;
	font-weight: 600;
}

.content-button-center {
	display: block;
	text-align: center;
}

.content-search {
	position: relative;
	padding: 0 15%;
	margin: 8px 0;
}

.content-search p {
	text-shadow: 0px 0px 10px #000000b3;
}

.content-search-bar {
	position: relative;
	display: block;
}

.content-search .search-btn {
	position: absolute;
	top: 0;
	left: 0;
}

.content-search .form-control {
	padding-left: 48px;
	box-shadow: 0px 3px 6px #00000029;
}

.content-search-tags {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: var(--xs-pad) 10%;
}

.search-tag {
	display: inline-block;
	text-decoration: none;
	font-size: 12px;
	line-height: 24px;
	font-weight: 300;
	margin: 6px;
	border-radius: 5px;
	padding: 3px 16px 3px 16px;
	color: rgba(255, 255, 255, 1);
	background: rgba(255, 255, 255, 0.24);
	border: 1px solid rgba(255, 255, 255, 0.32);
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	max-width: 40%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.search-tag:hover, .search-tag:focus, .search-tag:active {
	color: var(--main-color);
	border-color: var(--main-color);
	-webkit-transition: all 0.4s ease-out;
	transition: all 0.4s ease-out;
}

/* HOME STYLES *****************************/

#outer-wrapper.home-wrap {}

#content-carnage-wrapper-1:before {
	position: absolute;
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0%;
	background: url(../assets/images/bmg-carnage-banner.jpg) center top no-repeat;
	background-size: contain;
	z-index: 0;
}

#content-carnage-wrapper-1, #content-carnage-wrapper-2, #content-carnage-wrapper-3 {
	background-color: #120900;
}

#content-top-wrapper:before {
	position: absolute;
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0%;
	// background: url(../images/bmg-forum-bg.jpg) center top no-repeat;
	/* background-size: contain; */
	opacity: 0.72;
	z-index: 0;
}

#outer-wrapper.home-wrap:after {
	position: absolute;
	content: '';
	display: block;
	width: 100%;
	height: 80px;
	top: 0;
	left: 0;
	z-index: 1;
	background: linear-gradient(to bottom, rgba(18, 9, 0, 1) 8%, rgba(18, 9, 0, 0) 100%);
}

.home-wrap #content-wrapper {
	overflow: hidden;
}

.home-wrap #content-wrapper>div {
	position: relative;
	z-index: 1;
}

.home-wrap #content-wrapper:before {
	position: absolute;
	content: '';
	display: block;
	width: 100%;
	height: 2000px;
	top: 0;
	left: -50%;
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(85, 0, 122, 1)), color-stop(70%, rgba(85, 0, 122, 0)), color-stop(100%, rgba(85, 0, 122, 0)));
	background: -webkit-radial-gradient(center, ellipse cover, rgba(85, 0, 122, 1) 0%, rgba(85, 0, 122, 0) 70%, rgba(85, 0, 122, 0) 100%);
	background: radial-gradient(ellipse at center, rgba(85, 0, 122, 1) 0%, rgba(85, 0, 122, 0) 70%, rgba(85, 0, 122, 0) 100%);
	opacity: 0.56;
	z-index: 0;
}

.home-wrap #content-wrapper:after {
	position: absolute;
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	bottom: -25%;
	right: -50%;
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(122, 0, 0, 1)), color-stop(70%, rgba(122, 0, 0, 0)), color-stop(100%, rgba(122, 0, 0, 0)));
	background: -webkit-radial-gradient(center, ellipse cover, rgba(122, 0, 0, 1) 0%, rgba(122, 0, 0, 0) 70%, rgba(122, 0, 0, 0) 100%);
	background: radial-gradient(ellipse at center, rgba(122, 0, 0, 1) 0%, rgba(122, 0, 0, 0) 70%, rgba(122, 0, 0, 0) 100%);
	opacity: 0.36;
	z-index: 0;
}

.home-wrap #content-carnage-wrapper-1 .content-banner-title h1 {
	margin-bottom: 0;
}

/* .flipdown.flipdown__theme-light {
	font-family: 'soleil', Arial, Helvetica, sans-serif;
	font-weight: 300;
} */

.rotor-top, .rotor-bottom, .flipdown.flipdown__theme-light .rotor, .flipdown.flipdown__theme-light .rotor-top, .flipdown.flipdown__theme-light .rotor-leaf-front, .flipdown.flipdown__theme-light .rotor-bottom, .flipdown.flipdown__theme-light .rotor-leaf-rear {
	background-color: rgb(101 87 75) !important;
	color: var(--white-color) !important;
}

.flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):before, .flipdown.flipdown__theme-light .rotor-group:nth-child(n+2):nth-child(-n+3):before, .flipdown.flipdown__theme-light .rotor-group:nth-child(n+2):nth-child(-n+3):after {
	display: none;
}

.flipdown.flipdown__theme-light .rotor:after {
	border: none !important;
}

#content-carnage-wrapper-1 .big-btn {
	margin-top: 48px;
}

#content-carnage-wrapper-2 .content-col-3, #content-carnage-wrapper-3 .content-col-3 {
	display: flex;
	flex-direction: row;
}

#content-carnage-wrapper-2 .content-col-3 {
	align-items: center;
}

#content-carnage-wrapper-2 .content-banner-wrap {
	padding: 80px 0;
}

#content-carnage-wrapper-2 .content-col-3 div:nth-child(1) {
	width: 30%;
	padding-left: 0;
}

#content-carnage-wrapper-2 .content-col-3 div:nth-child(2) {
	width: 30%;
}

#content-carnage-wrapper-2 .content-col-3 div:nth-child(3) {
	width: 40%;
	padding-right: 0;
}

#content-carnage-wrapper-2 .content-col-3 .content-banner-txt, #content-carnage-wrapper-3 .content-col-3 .content-banner-txt {
	padding: 0 var(--main-pad);
}

#content-carnage-wrapper-3 .content-col-3 .content-banner-txt {
	width: 33.3333%;
	justify-content: flex-start;
}

#content-carnage-wrapper-3 .content-col-3 .content-banner-txt ul {
	padding: 0;
	list-style: none;
}

#content-carnage-wrapper-3 .content-col-3 .content-banner-txt ul li {
	position: relative;
	display: block;
	padding-left: 24px;
	line-height: 24px;
	color: var(--text-light);
}

#content-carnage-wrapper-3 .content-col-3 .content-banner-txt ul li:before {
	content: '-';
	position: absolute;
	display: block;
	left: 0;
	height: 24px;
	line-height: 24px;
	text-align: left;
}

#content-carnage-wrapper-2 .content-col-3 .banner-blk-img {
	width: 100%;
}

#content-carnage-wrapper-2 .content-center {
	padding-top: var(--lg-pad);
}

#content-carnage-wrapper-3 .content-col-3 {
	margin-left: -24px;
	margin-right: -24px;
}

#content-carnage-wrapper-3 {
	position: relative;
	// background: url(../images/bmg-roadmap-banner.jpg) center top no-repeat;
	background-size: cover;
}

#content-carnage-wrapper-3:before {
	content: '';
	display: block;
	position: absolute;
	height: 400px;
	width: 100%;
	left: 0;
	bottom: -200px;
	background: linear-gradient(to bottom, rgba(18, 9, 0, 0) 0%, rgba(18, 9, 0, 1) 40%, rgba(18, 9, 0, 1) 60%, rgba(18, 9, 0, 0) 100%);
	z-index: 3;
}

#content-carnage-wrapper-3 .content-banner-wrap {
	padding: 120px 0px 192px 0px;
}

.content-center {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.content-highlights {
	position: relative;
	padding: 96px 0;
}

.highlight-blocks {
	position: relative;
	display: flex;
	margin: 0 -12px var(--lg-pad) -12px;
}

.highlight-blocks.owl-carousel {
	width: initial;
}

.highlight-block-wrap {
	display: flex;
	width: 25%;
	padding: 0 12px;
}

.highlight-block {
	position: relative;
	display: flex;
	flex-direction: column;
	background: var(--dark-color);
}

.highlight-block a {
	position: relative;
	display: flex;
	flex-direction: column;
	border-radius: 17px;
	overflow: hidden;
	color: var(--white-color);
}

.highlight-block-img {
	position: relative;
	display: flex;
	flex-direction: column;
	border-radius: 17px;
	overflow: hidden;
	z-index: 1;
}

.highlight-block-img img {
	position: relative;
	display: block;
	overflow: hidden;
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.highlight-block-title {
	position: absolute;
	display: block;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: var(--main-pad);
	z-index: 3;
}

.highlight-block-title h3 {
	margin: 0;
}

.highlight-block-title span {
	position: relative;
	display: block;
	font-size: 14px;
	color: var(--text-light);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.highlight-block-bg {
	position: absolute;
	width: 100%;
	height: 80%;
	bottom: 0;
	left: 0;
	z-index: 2;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.hl-1 .highlight-block-bg {
	background: linear-gradient(to bottom, rgba(58, 94, 88, 0) 0%, rgba(58, 94, 88, 0.8) 56%, rgba(27, 57, 52, 1) 88%);
}

.hl-2 .highlight-block-bg {
	background: linear-gradient(to bottom, rgba(143, 35, 23, 0) 0%, rgba(143, 35, 23, 0.8) 56%, rgba(97, 15, 6, 1) 88%);
}

.hl-3 .highlight-block-bg {
	background: linear-gradient(to bottom, rgba(10, 103, 6, 0) 0%, rgba(10, 103, 6, 0.8) 56%, rgba(3, 81, 0, 1) 88%);
}

.hl-4 .highlight-block-bg {
	background: linear-gradient(to bottom, rgba(43, 93, 151, 0) 0%, rgba(43, 93, 151, 0.8) 56%, rgba(29, 66, 109, 1) 88%);
}

.highlight-block a:hover .highlight-block-img img {
	-webkit-transform: scale(1.08);
	transform: scale(1.08);
	-webkit-transition: all 0.7s ease-out;
	transition: all 0.7s ease-out;
}

/*
.highlight-block a:hover .highlight-block-bg{
	height: 100%;
	-webkit-transition: all 0.7s ease-out;
	transition: all 0.7s ease-out;
}
*/

#content-carnage-wrapper-1 {}

.cards-animation {
	position: relative;
	display: block;
	height: 360px;
}

.card-wrap {
	position: absolute;
	display: block;
	width: 40%;
	height: 100%;
	left: 33%;
	animation-iteration-count: infinite;
	animation-duration: 8s;
	transform-origin: 50% 100%;
}

.card-wrap img {
	max-width: 100%;
	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-1 {
	animation-name: anim-one;
	z-index: 3;
}

.card-2 {
	animation-name: anim-two;
	z-index: 4;
}

.card-3 {
	animation-name: anim-three;
	z-index: 5;
}

.card-4 {
	animation-name: anim-four;
	z-index: 2;
}

.card-5 {
	animation-name: anim-five;
	z-index: 1;
}

@keyframes anim-one {
	0%, 80%, 100% {
		transform: translate(0) rotate(0deg);
	}
	20%, 60% {
		transform: translate(-30px, 5px) rotate(-45deg);
	}
}

@keyframes anim-two {
	0%, 80%, 100% {
		transform: translate(0) rotate(0deg);
	}
	20%, 60% {
		transform: translate(-15px) rotate(-25deg);
	}
}

@keyframes anim-three {
	0%, 80%, 100% {
		transform: translate(0) rotate(0deg);
	}
	20%, 60% {
		transform: rotate(-5deg);
	}
}

@keyframes anim-four {
	0%, 80%, 100% {
		transform: translate(0) rotate(0deg);
	}
	20%, 60% {
		transform: translate(15px, 5px) rotate(15deg);
	}
}

@keyframes anim-five {
	0%, 80%, 100% {
		transform: translate(0) rotate(0deg);
	}
	20%, 60% {
		transform: translate(28px, 14px) rotate(35deg);
	}
}

/* MINT PAGE STYLES *****************************/

#banner-wrapper {
	position: relative;
	display: flex;
	height: 100%;
	min-height: 100vh;
	z-index: 1;
	// background: #ffcfdf;
	// background: url(../images/regenz-background.jpg) left center no-repeat;
	background-size: cover;
	overflow: hidden;
}

#banner-wrapper:before {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0%;
    background: url(../assets/images/bmg-carnage-banner.jpg) center top no-repeat;
    background-size: cover;
    z-index: 0;
}

#banner-main {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 112px;
}

#banner-wrapper .container {
	position: relative;
	display: flex;
	z-index: 2;
	align-items: center;
	justify-content: center;
}

.banner-main-wrap {
	position: relative;
	padding: 56px 0px;
	padding:0px;
	display: flex;
	flex-direction: column;
}
#banner-wrapper .banner-blk-img{
	padding-left: 0;
	padding-right: 48px;
	height: 100%;
	justify-content: center;
}
.banner-blk-img .logo-main{
	position: relative;
	display: block;
	text-align: center;
	width: 100%;
	margin: 0px 0px 48px 0px ;
}
.banner-blk-img .logo-main img{
	height: 56px;
}
#banner-wrapper .cards-animation{
	margin-bottom: 120px;
}
.progress-detailed{
	text-align: center;
}
.progress-buttons{
	display: flex;
	border: 1px solid #FFFFFF29;
	background: #FFFFFF14;
	padding: 24px 24px;
	border-radius: 5px;
}
.progress-buttons div{
	font-weight: 300;
}
.progress-button{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 50%;
}
.progress-buttons .progress-button:first-child{
	border-right: 1px solid #FFFFFF29;
}
.progress-button span{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	line-height: 32px;
	margin: 4px auto;
}
.progress-button span img{
	height: 32px;
	width: auto;
	opacity: 0.6;
	transition: opacity 0.2s ease;
}
.progress-button:hover span img{
	opacity: 1;	
	transition: opacity 0.4s ease-out;
}
.banner-mobile-txt{
	display: none;
}

.banner-text {
	position: relative;
	display: flex;
	flex-direction: column;
	text-align: left;
	align-self: center;
	width: 50%;
	padding-left: 48px;
}
.banner-text-main {
	position: relative;
	display: flex;
	flex-direction: column;
	text-align: left;
	align-self: center;
	width: 100%;
	height: 100%;
//	min-height: 800px;
	padding: 180px 48px;
	padding: 160px 64px;
	color: var(--dark-color);
	z-index: 3;
	background: url(../assets/images/bmg-scroll.png) center top no-repeat;
    background-size: contain;
    background-size: 100%;	
}
.banner-header {
	position: relative;
	display: flex;
	flex-direction: column;
}

.banner-main-price-block{
	position: relative;
	display: flex;
	margin-bottom: var(--main-pad); 
}
.banner-main-price-icon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: var(--sm-pad);
	width: 48px;
	height: 48px;
	text-align: center;
	opacity: 0.48;
}
.banner-main-price-icon img{
	height: 32px;
	width: auto;
}
.banner-main-price{
	position: relative;
	display: flex;
	line-height: 48px;
	font-size: 40px;
}
.banner-main-price div{
	font-weight: 700;
}

.banner-main-price span {
	font-weight: 400;
	padding-left: 16px;
	font-size: 20px;
	line-height: 32px;	
}

.banner-main-input-buttons{
	display: flex;
	margin-left: -8px;
	margin-right: -8px;
	margin-top: 8px;
	margin-bottom: 24px;
}
.banner-main-input-buttons .button{
	margin: 0 8px;
	width: 16.6666%;
	width: calc(16.6666% - 16px);
	border: 1px solid #12090029;
	color: var(--dark-color);
	background: none;
}
.banner-main-input-buttons .button:hover{
	border: 1px solid var(--main-color);
	color: var(--main-color);

}
.banner-main-input-top{
	display: flex;
}
.banner-main-input-top input {
	margin-right: var(--sm-pad);
	width: 56px;
	height: 56px;
	border: 1px solid #1209007A;
	border-radius: 7px;
	background: transparent;
	text-align: center;
	color: var(--dark-color);
}
.banner-main-input-top input::-webkit-outer-spin-button,
.banner-main-input-top input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.banner-main-input-top input[type=number] {
    -moz-appearance:textfield;
}
.banner-main-input-top .button{
	width: calc(100% - 72px);
}

.MuiDialog-paper{
	margin: 0;
	padding: 0px;
	background: linear-gradient(180deg, #331A00 0%, #190C00 100%) 0% 0%;
	border: 1px solid #FFFFFF29;
	min-width: 600px;
	max-width: 90%;
	color: #FFFFFF !important;
}
.MuiDialog-paper p,
.subtitle-loader{
	color: #FFFFFF7A!important;
}

.MuiDialogTitle-root .MuiTypography-h6{
	font-size: 26px !important;
}
#showcase-dialog .swiper-wrapper{
	margin-left: 0;
}
#showcase-dialog .swiper-slide{
	text-align: center;
}
#showcase-dialog .swiper-slide .mintcard-wrap{
	margin: 0 auto;
}



/* TAG STYLES *****************************/

.TagsPage-content {}

.TagTiles {
	display: flex;
	list-style-type: none;
	padding: 0;
	margin: 0 -16px;
	overflow: hidden;
}

.TagTiles>li {
	display: flex;
	flex-direction: column;
	width: 33.333%;
	margin: 0 16px;
	min-height: 200px;
	overflow: hidden;
}

.TagTile, .TagTile.colored {
	display: flex;
	flex-direction: column;
	padding: 24px;
	background: rgba(255, 255, 255, 0.16);
	border-radius: 17px;
}

/* LOADING STYLES *****************************/

.form-loading {
	position: absolute;
	display: none;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 99;
	background: rgba(255, 255, 255, 0.95);
	text-align: center;
	flex-direction: column;
	justify-content: center;
	opacity: 0;
}

.loading-icon {
	position: relative;
	display: inline-block;
	width: 80px;
	max-width: 25%;
	text-align: center;
	align-self: center;
	margin-top: -10%;
}

.loading-txt {
	display: block;
	font-size: 1.8rem;
}

.form-loading.show {
	display: -webkit-flex;
	display: flex;
	opacity: 1;
	-webkit-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

/* FOOTER *****************************/

#footer {
	position: relative;
	display: block;
	color: var(--white-color);
	padding: 0px;
	font-size: 14px;
	z-index: 2;
}

.footer-content {
	display: flex;
	flex-direction: row;
	padding: 48px 0;
}

.footer-content h3 {
	font-weight: 300;
	margin-bottom: var(--sm-pad);
}

.footer-logo-main {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 40%;
	padding-right: 20%;
}

.footer-logo {
	display: inline-block;
	margin-bottom: 16px;
}

.footer-logo img {
	height: 32px;
	width: auto;
}

.footer-menu {
	display: flex;
	flex-direction: column;
	width: 20%;
}

.footer-menu-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.footer-menu-list a {
	line-height: 32px;
	color: rgba(255, 255, 255, 0.48);
}

.footer-menu-list a:hover {
	color: var(--hover-color);
}

.footer-menu-btns {
	display: flex;
}

.footer-menu-btns .button {
	display: inline-block;
	align-self: flex-start;
}

.footer-menu-btns .button+.button {
	margin-left: 16px;
}

.footer-bottom {
	display: flex;
	flex-direction: row;
	padding: 24px 0 48px 0;
	border-top: 1px solid rgba(255, 255, 255, 0.16);
}

.footer-copyright {
	display: flex;
	flex-direction: row;
	width: 50%;
}

.footer-bottom-right {
	display: flex;
	flex-direction: row;
	width: 50%;
}

.footer-social {
	position: relative;
	display: flex;
	flex-direction: row;
	list-style: none;
	margin: 0;
	margin-left: auto;
	padding: 0;
}

.footer-social li {
	padding: 0;
	margin: 0 0 0 16px;
}

.footer-social a {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	width: 24px;
	width: 24px;
	font-size: 16px;
}

/* MODAL STYLES *****************************/

body.modal-open {
	padding: 0px!important;
	overflow-y: hidden;
}

.modal-backdrop {
	background: #FFFFFF;
}

.modal-backdrop.in {
	opacity: 0.9;
}

.modal-backdrop.show {
	opacity: 0.9;
}

.modal {
	z-index: 9999;
}

.modal-open .modal {}

.modal.modal-static .modal-dialog {
	-webkit-transform: none;
	transform: none;
}

.modal-dialog {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 33%;
	min-width: 520px;
	max-width: 90%;
	align-self: center;
	margin: 0px auto;
}

.modal-content {
	position: relative;
	background: #FFF;
	border: 0px;
	box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
}

.modal-content .close {
	position: absolute;
	right: 28px;
	top: 30px;
	opacity: 1;
	margin: 0px;
	width: 22px;
	height: 22px;
	/*	border-radius: 50%;*/
	font-size: 12px;
	line-height: 10px;
	text-align: center;
	font-weight: 400;
	color: #FFF;
	border: 1px solid #FFF;
}

.modal-header {
	padding: 48px 48px 48px 48px;
	border: 0px;
}

.modal-header h2 {
	margin-bottom: 16px;
	font-size: 26px;
	color: var(--sub-color);
}

.modal-header p {
	margin: 0;
	color: var(--grey-color);
	/*    margin-bottom: 16px;*/
}

.modal-body {
	padding: 0px 48px;
}

.modal-padding .modal-body {
	padding-bottom: 48px;
}

.modal-body .form-btns {
	text-align: right;
}

.modal-body p {
	margin: 0;
	margin-bottom: 16px;
}

.modal-footer {
	padding: 30px;
	border: 0px;
	text-align: left;
}

/* OTHER STYLES *****************************/

.clear {
	display: block;
	clear: both;
}

.no_border {
	border: none!important;
}

.mob-break {
	display: none;
}

.web-break {
	display: inline;
}

.show-mob {
	display: none
}

/* ANIMATIONS *****************************/

@-webkit-keyframes opac-in-animate {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes opac-in-animate {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes opac-out-animate {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes opac-out-animate {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-webkit-keyframes btn-animate {
	0%, 100% {
		-webkit-transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.1);
	}
}

@keyframes btn-animate {
	0%, 100% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
}

@-webkit-keyframes nav-animate {
	0% {
		-webkit-transform: translateY(-100%);
	}
	100% {
		-webkit-transform: translateY(0%);
	}
}

@keyframes nav-animate {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0%);
	}
}

@-webkit-keyframes left-in-animate {
	0% {
		-webkit-transform: translateX(-50%);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(0%);
		opacity: 1;
	}
}

@keyframes left-in-animate {
	0% {
		transform: translateX(-50%);
		opacity: 1;
	}
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}

@-webkit-keyframes right-in-animate {
	0% {
		-webkit-transform: translateX(50%);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(0%);
		opacity: 1;
	}
}

@keyframes right-in-animate {
	0% {
		transform: translateX(50%);
		opacity: 1;
	}
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}

@-webkit-keyframes top-in-animate {
	0% {
		-webkit-transform: translateY(-100%);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(0%);
		opacity: 1;
	}
}

@keyframes top-in-animate {
	0% {
		transform: translateY(-100%);
		opacity: 1;
	}
	100% {
		transform: translateY(0%);
		opacity: 1;
	}
}

@-webkit-keyframes btm-in-animate {
	0% {
		-webkit-transform: translateY(100%);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(0%);
		opacity: 1;
	}
}

@keyframes btm-in-animate {
	0% {
		transform: translateY(100%);
		opacity: 1;
	}
	100% {
		transform: translateY(0%);
		opacity: 1;
	}
}

@-webkit-keyframes btm-hover-animate {
	0% {
		-webkit-transform: translateY(0%);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(-5%);
		opacity: 1;
	}
}

@keyframes btm-hover-animate {
	0% {
		transform: translateY(0%);
		opacity: 1;
	}
	100% {
		transform: translateY(-5%);
		opacity: 1;
	}
}

@-webkit-keyframes zoom-in-animate {
	0% {
		-webkit-transform: scale(0.5);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		opacity: 1;
	}
}

@keyframes zoom-in-animate {
	0% {
		transform: scale(0.1);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@-webkit-keyframes zoom-pop-animate {
	0% {
		-webkit-transform: scale(0);
		opacity: 1;
	}
	90% {
		-webkit-transform: scale(1.2);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		opacity: 1;
	}
}

@keyframes zoom-pop-animate {
	0% {
		transform: scale(0);
		opacity: 1;
	}
	90% {
		transform: scale(1.2);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@-webkit-keyframes btm-fade-animate {
	0% {
		-webkit-transform: translateY(15%);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0%);
		opacity: 1;
	}
}

@keyframes btm-fade-animate {
	0% {
		transform: translateY(15%);
		opacity: 0;
	}
	100% {
		transform: translateY(0%);
		opacity: 1;
	}
}

/* RESPONSIVE STYLES *****************************/

.container {
	/*    max-width: 1560px;*/
	padding-right: 24px;
	padding-left: 24px;
}

.row {
	margin-right: -24px;
	margin-left: -24px;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
	padding-right: 48px;
	padding-left: 48px;
	padding-right: 24px;
	padding-left: 24px;
}


/* DIALOG  STYLES *****************************/
.MuiDialog-paperWidthSm {
	border-radius: 21px !important;
	max-width: 496px !important;
}

.MuiTypography-h6 {
	font-size: 20px !important;
	font-weight: 700 !important;
	user-select: none;
}

.MuiDialogTitle-root {
	padding: var(--lg-pad) var(--lg-pad) var(--xs-pad) var(--lg-pad) !important;
	text-align: center;
}

.MuiDialogContent-root {
	padding: 0 var(--lg-pad) !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden !important;
}

.MuiDialogContent-root .MuiDialogContentText-root {
	margin-bottom: 0;
}

.MuiDialogContentText-root {
	font-size: var(--main-font);
	color: var(--text-color) !important;
	text-align: center;
}

.slider-wrap {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
}

.slider-wrap.is-loading {
	justify-content: center;
	padding: 96px;
}

.slider-wrap .previous,
.slider-wrap .next {
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	cursor: pointer;
	color: var(--text-color);
	z-index: 999;
}

.slider-wrap .previous {
	left: 48px;
}

.slider-wrap .next {
	right: 48px;
}

.alert-img {
	cursor: pointer;
}

.alert-img.placeholder {
	// background: #dddddd;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 200px;
}

.alert-img img,
.alert-img.placeholder {
	border-radius: 7px;
	user-select: none;
}

.alert-img.placeholder div {
	width: 80px;
	opacity: 0.24;
}

.alert-img-amount {
	text-align: center;
	font-weight: 700;
	color: var(--text-color);
	margin-top: var(--main-pad);
	user-select: none;
}

.alert-dialog-loading {
	text-align: center;
	padding: var(--lg-pad) 0;
}

.alert-dialog-loading-icon {
	display: flex;
	justify-content: center;
	text-align: center;
	margin-bottom: var(--main-pad);
}

.alert-dialog-loading-icon span span,
.slider-wrap span span {
	border: 6px solid #FFFFFF7A !important;
}

.alert-dialog-loading-title {
	margin: 0;
	text-align: center;
	font-size: var(--xl-font);
	font-weight: 600;
	font-family: 'soleil', Arial, Helvetica, sans-serif;
}

.MuiTypography-h6 {
	font-family: 'soleil', Arial, Helvetica, sans-serif !important;
}

.loader-dialog .MuiDialog-paperWidthSm {
	min-width: 304px !important;
	min-height: 160px !important;
}

.MuiCircularProgress-colorSecondary {
	color: var(--sub-color) !important;
}

.MuiDialogActions-root {
	padding: var(--lg-pad) !important;
}

.MuiDialogActions-root button {
	width: 100%;
	height: 48px;
}

.MuiDialogActions-spacing> :not(:first-child) {
	margin-left: var(--sm-pad) !important;
}

.swiper-wrapper {
	margin-left: 88px;
	padding: var(--lg-pad) 0px var(--main-pad) 0px;
}

/* Cards STYLES *****************************/
.mintcard-wrap {
	width: 232px;
	height: 100%;
	padding: var(--sm-pad);
	display: flex;
	border-radius: 9px;
	flex-direction: column;
}

.card-name {
	padding-top: var(--sm-pad);
	color: var(--main-color);
	font-size: 20px;
	font-weight: 700;
	padding-bottom: var(--xs-pad);
}

.card-btm {
	margin-top: 8px;
}

.card-btm .button {
	border-radius: 9999px;
	border: 1px solid var(--sub-color);
	color: var(--sub-color);
	padding: 9px 12px 9px 12px;
}

@media (min-width: 1200px) {
	#navbar.sticky-ani {
		position: fixed;
		top: -100px;
	}
	#navbar.sticky {
		position: fixed;
		top: 0;
		transition: 0.4s ease-out top;
		-webkit-transition: 0.4s ease-out top;
	}
}

@media (min-width: 1921px) {
	#content-top-wrapper::before {
		background-size: cover;
	}
}

@media (min-width: 1600px) {
	.container {
		max-width: 1296px;
		padding-right: 24px;
		padding-left: 24px;
	}
}

@media (min-width: 1200px) and (max-width: 1599px) {
	body {}
	h1 {
		font-size: 40px;
	}
	h2 {
		font-size: 32px;
	}
	h3 {
		font-size: 24px;
	}
	a.button, .button {}
	.container {
		max-width: 1296px;
		padding-right: 24px;
		padding-left: 24px;
	}
	/*
	#navbar{
		padding: 12px 8px;
	}
	#nav-main ul li{
		margin: 0 16px;
	}
	#nav-right ul.nav-social{
		padding: 0;
	}
	#nav-right ul li{
		padding: 0;
	}
*/
}

@media (min-width: 1200px) and (max-width: 1399px) {
	
	#banner-main{
		padding-top: 120px;
		padding-bottom: 64px;
	}
	#banner-wrapper .cards-animation{
		margin-bottom: 72px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	body {
		/*        font-size: 18px;*/
	}
	.container {
		max-width: 100%;
		padding-left: 24px;
		padding-right: 24px;
	}
	h1 {
		font-size: 40px;
	}
	h2 {
		font-size: 28px;
	}
	h3 {
		font-size: 24px;
	}
	#navbar .container {
		padding-left: 0px;
		padding-right: 0px;
	}
	
	#banner-main{
		padding-top: 120px;
	}
	#banner-wrapper .cards-animation{
		margin-bottom: 0;
	}
	
}

@media (min-width: 768px) and (max-width: 991px) {
	.container {
		max-width: 100%;
		padding-left: 24px;
		padding-right: 24px;
	}
	body {
		font-size: 14px;
	}
	h1, .article-header h1 {
		font-size: 30px;
	}
	h2 {
		font-size: 28px;
	}
	h3 {
		font-size: 20px;
	}
	#navbar {
		padding: 0px;
	}
	#navbar .container {
		padding-left: 0px;
		padding-right: 0px;
	}
}

@media (max-width: 767px) {
	:root {
		--xs-pad: 8px;
		--sm-pad: 16px;
		--main-pad: 24px;
		--lg-pad: 48px;
		--xl-pad: 72px;
		--xxl-pad: 96px;
		--lg-neg: -48px;
		--main-neg: -24px;
		--main-font: 16px;
		--sm-font: 14px;
		--input-font: 14px;
		--button-font: 16px;
		--sm-icon: 18px;
		--main-icon: 32px;
		--large-icon: 96px;
	}
	.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
		padding-right: 16px;
		padding-left: 16px;
	}
	body {
		font-size: 14px;
	}
	.container {
		padding-right: 16px;
		padding-left: 16px;
		padding-right: 24px;
		padding-left: 24px;
	}
	.row {
		margin-right: -16px;
		margin-left: -16px;
	}
	h1 {
		font-size: 26px;
	}
	h2 {
		font-size: 20px;
	}
	h3 {
		font-size: 18px;
		margin-bottom: 8px;
		line-height: 1.2;
	}
	p {
		margin-bottom: 1.6rem;
	}
	.lead {
		font-size: 18px;
		margin-bottom: 16px;
		margin-bottom: 1.6rem;
	}
	h3+.lead {
		margin-top: 16px;
		margin-top: 1.6rem;
	}
	.sub-title {
		font-size: 11px;
		margin-bottom: 8px;
	}
	input.button, .button, .button:visited, .w-btn, .l-btn, .c-btn {
		line-height: 22px;
	}
	#content-carnage-wrapper-1 .big-btn {
		width: 100%;
	}
	a.button, .w-btn {}
	a.button, .button, .w-btn, .w-btn:visited {
		font-size: 12px;
		line-height: 30px;
		padding: 9px 24px 9px 24px;
		letter-spacing: 1px;
	}
	.l-btn, .l-btn:visited {
		font-size: 12px;
		padding: 9px 24px 9px 24px;
	}
	.btn-clean {
		font-size: 14px;
		padding: 10px 16px;
	}
	.btn-clean i {
		height: 20px;
		line-height: 20px;
		width: 20px;
	}
	.mob-break {
		display: inline;
	}
	.web-break {
		display: none;
	}
	.hide-mob {
		display: none;
	}
	.show-mob {
		display: inline-block;
	}
	#outer-wrapper.home-wrap {
		overflow: hidden;
	}
	#outer-wrapper.home-wrap:before {
		width: 200%;
		left: -50%;
	}
	#content-carnage-wrapper-1 .content-banner-wrap {
		padding: 56px 0px 0px 0px;
	}
	#content-carnage-wrapper-1 .content-banner-title, #content-carnage-wrapper-1 .content-banner-txt {
		margin-bottom: var(--sm-pad);
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	#content-carnage-wrapper-1 .content-banner-txt .light-white {
		margin-bottom: var(--md-pad);
	}
	#content-carnage-wrapper-2 .content-banner-wrap {
		padding: 24px 0px 0px 0px !important;
	}
	#content-carnage-wrapper-2 .content-col-3 {
		flex-direction: column !important;
	}
	.home-wrap #content-carnage-wrapper-2 .content-col-3 .content-banner-txt, .home-wrap #content-carnage-wrapper-2 .content-col-3 div:nth-child(3) {
		width: 100% !important;
		padding: 0;
	}
	.home-wrap #content-carnage-wrapper-2 .content-center {
		padding: var(--md-pad) 0;
	}
	#content-carnage-wrapper-3 .content-banner-wrap {
		padding: 120px 0px 120px 0px;
	}
	.home-wrap #content-carnage-wrapper-3 .content-col-3 {
		flex-direction: column;
		margin-left: 0;
		margin-right: 0;
	}
	.home-wrap #content-carnage-wrapper-3 .content-col-3 .content-banner-txt {
		width: 100%;
		padding: 0;
	}
	#content-top-wrapper:before {
		background-size: contain;
	}
	#content-carnage-wrapper-1:before {
		background-size: auto;
		background-position: 34% 0%;
	}
	/* #content-carnage-wrapper-1 .content-banner {
		flex-direction: column;
	}
	 */
	#content-top-block {
		padding: 0;
		min-height: initial;
	}
	.content-highlights {
		padding: 24px 0px;
	}
	#content-top-block .content-search {
		padding: 0;
		margin: 24px 0px;
		position: relative;
	}
	.content-search-tag-wrap {
		display: block;
		overflow: hidden;
		overflow-x: auto;
		margin: 0 -24px;
		padding-right: 24px;
	}
	.content-search-tags {
		position: relative;
		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-start;
		width: initial;
		margin: 0 24px 0px 0px;
		padding: 0 16px;
	}
	.search-tag {
		position: relative;
		cursor: pointer;
		float: left;
		margin: 0px 16px 8px 0px;
		align-self: flex-start;
		white-space: nowrap;
		max-width: initial;
		overflow: initial;
	}
	.highlight-blocks {
		position: relative;
		display: flex;
		margin: 0 -24px var(--lg-pad) -24px;
	}
	#highlight-slider {
		margin-left: -96px!important;
	}
	.highlight-block-wrap {
		width: 100%;
	}
	.content-banner-wrap {
		padding: 24px 0px 0px 0px;
	}
	#content-home-2 {
		padding-top: 48px;
	}
	.content-banner {
		margin: 0;
		flex-direction: column;
	}
	.banner-blk-txt, .banner-blk-img {
		padding: 0 0px;
		width: 100%;
		margin-bottom: 24px;
	}
	.banner-blk-img {
		order: 1;
		margin-bottom: 24px;
		/* padding: 24px 48px 0 48px; */
		padding: 0;
	}
	.content-banner-title {
		margin-bottom: 12px;
	}
	.banner-blk-txt {
		order: 2;
	}
	.cards-animation {
		height: 200px;
	}
	#content-carnage-wrapper-3:before {
		height: 144px;
		bottom: -56px;
	}
	body.modal-open {
		position: fixed;
		width: 100%;
	}
	.modal-backdrop.show {
		opacity: 1;
	}
	.modal-dialog {
		display: block;
		width: 100%;
		min-width: initial;
		max-width: initial;
	}
	.modal-content {
		border-radius: 0;
		box-shadow: none;
	}
	.modal-header {
		padding: 24px;
	}
	.modal-body {
		padding: 0 24px;
	}
	.modal-padding .modal-body {
		padding-bottom: 24px;
	}
	.footer-content {
		padding: 24px 0 24px 0;
		flex-wrap: wrap;
		justify-content: center;
	}
	.footer-content h3 {
		margin-bottom: 4px;
	}
	.footer-logo-main {
		text-align: center;
		width: 100%;
		padding-right: 0;
	}
	.footer-menu {
		width: 50%;
		margin-bottom: 16px;
	}
	.footer-menu.footer-buttons {
		width: 100%;
	}
	.footer-menu-list a {
		line-height: 32px;
	}
	.footer-menu-btns .button {
		display: inline-block;
		align-self: flex-start;
	}
	.footer-menu-btns .button+.button {
		margin-left: 16px;
	}
	.footer-bottom {
		display: flex;
		flex-direction: column;
		padding: 24px 0 48px 0;
		border-top: 1px solid rgba(255, 255, 255, 0.16);
	}
	.footer-copyright {
		display: block;
		text-align: center;
		width: 100%;
		margin-bottom: 16px;
	}
	.footer-bottom-right {
		text-align: center;
		width: 100%;
	}
	.footer-social {
		margin: 0 auto;
	}
	.footer-social li {
		margin: 0 8px;
	}
	
	#banner-main{
		padding-top: 72px;
	}
	#banner-wrapper .banner-blk-img{
		order: 1;
		padding: 0;
		padding-bottom: 240px;
	}
	
	.banner-blk-img .logo-main img{
		height: 32px;
	}
	
	.progress-buttons{
		padding: 16px;
	}
	
	.banner-mobile-txt{
		display: block;
	}
	.banner-main-price-block{
		justify-content: center;
	}
	
	.banner-text{
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		padding: 0;
	}
	.banner-text .banner-header,
	.banner-text .banner-main-txt{
		display: none;
	}
	.banner-text-main {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		padding: 16px 16px;
		background: none;
		z-index: 3;		
		background: url(../assets/images/bmg-scroll-mobile.png) center top no-repeat;
		background-size: cover;
	}
	.banner-main-input-buttons .button{
		text-align: center;
		font-size: 16px;
		padding: 3px 8px;
	}
	#showcase-dialog .MuiDialog-paper{
		min-width: initial;
		margin: 0 16px;
	}
	.MuiDialogTitle-root .MuiTypography-h6 {
		font-size: 18px !important;
	}
	.MuiDialogTitle-root {
    	padding: 24px 24px 4px 24px !important;	
	}
	.MuiDialogContent-root{
		padding: 0px 24px !important;	
	}
	.MuiDialogActions-root{
		padding: 24px !important;	
	}
	.swiper-wrapper{
		padding: 16px 0px;
	}
}

@media (min-width: 400px) and (max-width: 767px) {}

@media (min-width: 320px) and (max-width: 361px) {
	.banner-img-bottom {
		margin-left: 0%;
		margin-right: 0%;
	}
}

/*MOBILE NAV*/

@media (max-width: 1199px) {
	/*
	#nav-main, #nav-right{
		display: none;
	}
*/
	#navbar {
		position: fixed;
		top: 0;
	}
	#navbar.sticky-ani {
		position: fixed;
		top: -56px;
	}
	#navbar.sticky {
		position: fixed;
		top: 0;
		-webkit-transition: 0.5s ease-out top;
		transition: 0.5s ease-out top;
	}
	.menu-open #navbar {
		box-shadow: none;
		top: 0!important;
		background: var(--dark-color);
	}
	/*
	.menu-open .navbar-header {
		background: #FFFFFF;

	    -webkit-transition: background 0.01s ease-out;
		transition:background 0.01s ease-out;
		-webkit-transition-delay: 0.4s;
		transition-delay: 0.4s;		
	}
*/
	.menu-open.menu-ani .navbar-header {
		background: none;
		-webkit-transition: background 0.01s ease-out;
		transition: background 0.01s ease-out;
		-webkit-transition-delay: 0s;
		transition-delay: 0s;
	}
	.navbar-header {
		justify-content: flex-start;
	}
	#navbar {
		padding: 0px 24px;
	}
	.nav-toggle {
		display: block;
		float: left;
		width: 24px;
		height: 24px;
		flex-shrink: 0;
	}
	.logo-main {
		margin: 0;
		margin: 14px 0px;
		display: block;
	}
	.logo-main img {
		height: 28px;
		width: auto;
		vertical-align: top;
	}
	.logo-main .logo-text {
		padding: 2px 0px;
	}
	.nav-wrap {
		position: absolute;
		position: fixed;
		display: block;
		float: none;
		top: 56px;
		left: -110%;
		width: 270px;
		height: 90%;
		height: calc(100% - 72px);
		margin: 0;
		padding: 0 8px 24px 8px;
		z-index: 2;
		background: var(--dark-color);
		opacity: 0;
		overflow-y: auto;
		-webkit-transition: 0.2s ease-out all;
		transition: 0.2s ease-out all;
	}
	.menu-open .nav-wrap {
		display: flex;
		flex-direction: column;
		justify-content: top;
		height: 100vh;
		width: 270px;
		left: 0px;
		opacity: 1;
		-webkit-transition: 0.5s ease-out left;
		transition: 0.5s ease-out left;
	}
	.menu-ani .nav-wrap {
		left: -110%;
		opacity: 0;
	}
	#nav-main {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	#nav-main ul {
		margin: 0;
	}
	#nav-main ul li {
		float: none;
		width: 100%;
		margin: 0;
		text-align: left;
		padding: 0 24px;
	}
	#nav-main ul li a {
		font-weight: 600;
	}
	#nav-right {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0 24px;
	}
	#nav-right .button {
		display: block;
		margin: 0px;
		font-size: 14px;
		margin-bottom: 16px;
	}
	#nav-right .button.btn-clean {
		text-align: left;
		padding-left: 0;
	}
}

/******** iPhone Fixes ********/

/* iPhone 8 with notch */

@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {}

/* iPhone X with notch */

@media only screen and (width: 375px) and (-webkit-device-pixel-ratio: 3) {}

/* iPhone XR with notch */

@media only screen and (width: 414px) and (-webkit-device-pixel-ratio: 2) {}

/* iPhone XS Max with notch */

@media only screen and (width: 414px) and (-webkit-device-pixel-ratio: 3) {}